<template>
  <div>
    <div class="container">
      <SubHeader :pathList="pathList" :title="title" />

      <!-- contents -->
      <div id="contents" class="contents">
        <div class="title-sort-box">
          <h3>{{ $t('subMenu.alarm') }}</h3>
          <p class="red-txt" v-html="$t('common.required-input-guide')"></p>
        </div>
        <!--/title-sort-box-->
        <div class="table-horizon st01">
          <ValidationObserver ref="form">
            <form enctype="multipart/form-data">
              <table class="form-md">
                <colgroup>
                  <col style="width:10%" />
                  <col style="width:37%" />
                  <col style="width:16%" />
                  <col style="width:37%" />
                </colgroup>
                <tbody>
                  <tr>
                    <th>
                      <label class="ic-star">{{
                        $t('alarm.label-serviceId')
                      }}</label>
                    </th>
                    <td>
                      <text-input
                        type="text"
                        rules="required"
                        label="srvcId"
                        name="srvcId"
                        v-model.trim="formData.srvcId"
                        inputStyle="width: 102%; background: #eee; color: #aaa; border-color: #eee;"
                        disabled
                      />
                    </td>
                    <th>
                      <label>{{ $t('alarm.label-pushRedirectUrl') }}</label>
                    </th>
                    <td>
                      <text-input
                        :rules="{
                          required: true,
                          regex: /^http(s)?:\/\/?[\w.-]+(?:\.[\w\.-]+)+[\w\-\._~:/?#[\]@!\$&'\(\)\*\+,;=.]+$/,
                        }"
                        :errorData="urlInputErr"
                        label="pushRedirectUrl"
                        name="pushRedirectUrl"
                        v-model.trim="formData.pushRedirectUrl"
                        :placeholder="urlPlaceholder"
                      />
                    </td>
                  </tr>
                  <tr>
                    <th>
                      <label>{{ $t('alarm.label-criteriaList') }}</label>
                    </th>
                    <td>
                      <table class="alarm-rule-dtl-table">
                        <colgroup>
                          <col style="width:40%" />
                          <col style="width:50%" />
                          <col style="width:10%" />
                        </colgroup>
                        <thead>
                          <tr>
                            <th>{{ $t('alarm.label-criteriaCode') }}</th>
                            <th>{{ $t('alarm.label-criteriaEndDate') }}</th>
                            <th></th>
                          </tr>
                        </thead>
                        <tbody>
                          <tr
                            v-for="(criteria, index) in formData.criteriaList"
                            :key="index"
                          >
                            <td>
                              <select
                                class="isf term-sel"
                                name="criteriaCodeName"
                                v-model="criteria.criteriaCode"
                              >
                                <option value="">{{
                                  $t('alarm.select-option-default-criteriaCode')
                                }}</option>
                                <option
                                  v-for="(code, index) in healthCodeList"
                                  :key="index"
                                  :value="code.codeName"
                                  >{{ code.description }}</option
                                >
                              </select>
                            </td>
                            <td>
                              <date-picker
                                v-model="criteria.endDate"
                                mode="dateTime"
                                :min-date="new Date()"
                                :masks="{
                                  inputDateTime: 'YYYY/MM/DD HH:mm:ss',
                                }"
                              >
                                <template
                                  #default="{ inputValue, togglePopover}"
                                >
                                  <span
                                    class="daterange-wrap"
                                    style="width: 100%"
                                  >
                                    <text-input
                                      label="endDate"
                                      name="endDate"
                                      maxlength="20"
                                      :value="inputValue"
                                      @click="togglePopover"
                                      placeholder="yyyy/mm/dd hh:MM:ss"
                                      :inputStyle="
                                        new Date(criteria.endDate) < new Date()
                                          ? 'color:red'
                                          : 'color:black'
                                      "
                                    />
                                    <label
                                      for="endDate"
                                      @click="togglePopover"
                                      class="ic-calendar"
                                    ></label>
                                  </span>
                                </template>
                              </date-picker>
                            </td>
                            <td style="vertical-align: inherit">
                              <input
                                type="button"
                                class="bt-line22 btnAddRow"
                                :class="
                                  formData.criteriaList.length === index + 1
                                    ? 'bt-num-plus'
                                    : 'bt-num-minus'
                                "
                                @click="manageRow($event, index)"
                              />
                            </td>
                          </tr>
                        </tbody>
                      </table>
                    </td>
                    <th></th>
                    <td></td>
                  </tr>
                </tbody>
              </table>
            </form>
          </ValidationObserver>
        </div>
        <div class="btns-bottom">
          <button type="button" class="bt-md bt-red" @click="checkValidate">
            <span>{{ $t('common.btn-submit') }}</span>
          </button>
        </div>
      </div>
    </div>
    <password-check-popup
      v-if="pwdCheckPopup"
      @close="pwdCheckPopup = false"
      :fetchApi="checkPassword"
      @next="setFormData"
    />
  </div>
</template>
<script>
import TextInput from '@/components/common/TextInput';
import PasswordCheckPopup from '@/components/common/PasswordCheckPopup.vue';
import CommonMixins from '@/mixins/CommonMixins';
import { fetchAlarmList, insertAlarm } from '@/api/subscriptionApi';
import { getCommonCodes } from '@/api/commonApi';
import { checkPassword } from '@/api/serviceApi';
import { COMMON_GROUP_CODE } from '@/store/properties';

export default {
  mixins: [CommonMixins],
  components: { TextInput, PasswordCheckPopup },
  data() {
    return {
      pathList: [
        this.$i18n.t('menu.home'),
        this.$i18n.t('menu.subscriptionAdmin'),
        this.$i18n.t('subMenu.alarm'),
      ],
      title: {
        mainTitle: this.$i18n.t('menu.subscriptionAdmin'),
        smallTitle: this.$i18n.t('menu.subscriptionAdmin-eng'),
      },
      urlPlaceholder: this.$i18n.t('common.placeholder.url-format'),
      urlInputErr: {
        message: this.$i18n.t('subscription.input-warn-url-format'),
      },
      formData: {
        srvcId: '',
        pushRedirectUrl: '',
        criteriaList: [],
      },
      healthCodeList: [],
      pwdCheckPopup: false,
      category: 'alarm',
    };
  },

  created() {
    this.initView();
  },

  methods: {
    // 화면 초기화
    initView() {
      this.getCommonCodes();
      this.fetchAlarmList();
    },
    // 헬스코드 조회
    async getCommonCodes() {
      const { data } = await getCommonCodes(
        `${COMMON_GROUP_CODE.CODE_SUBSCRIPTION_HEALTH_CODE}`,
      );

      this.healthCodeList = data;
    },

    // 알람 목록 조회
    async fetchAlarmList() {
      this.$dialogs.loading();

      const { data } = await fetchAlarmList().catch(() => {
        this.$dialogs.close();
      });

      this.formData = data;
      this.formData.criteriaList.push({
        endDate: '',
        criteriaCode: '',
        criteriaCodeName: '',
      });

      this.$dialogs.close();
    },

    // row 추가/삭제 버튼
    manageRow({ target }, index) {
      if (target.classList.contains('bt-num-plus')) {
        this.formData.criteriaList.push({
          endDate: '',
          criteriaCode: '',
          criteriaCodeName: '',
        });
      } else {
        this.formData.criteriaList.splice(index, 1);
      }
    },

    // 페이지 유효성 검사
    checkValidate() {
      this.$refs.form.validate().then(res => {
        if (!res) {
          this.$dialogs.alert(this.$i18n.t('common.invalid-required'));
          return;
        }

        // endDate check
        for (let i = 0; i < this.formData.criteriaList.length; i++) {
          const criteria = this.formData.criteriaList[i];

          if (criteria.endDate === '' || criteria.endDate === null) continue;

          const endDate = new Date(criteria.endDate);

          if (endDate < new Date()) {
            this.$dialogs.alert(
              this.$i18n.t('subscription.input-warn-endDate'),
            );
            return;
          }
        }

        this.pwdCheckPopup = true;
      });
    },

    // 비밀번호 확인
    checkPassword(params) {
      return checkPassword(params);
    },

    // 저장 데이터 set
    setFormData() {
      this.pwdCheckPopup = false;

      // criteriaCode가 입력되지 않은 row 삭제, endDate to String
      let criteriaList = [];
      for (let i = 0; i < this.formData.criteriaList.length; i++) {
        const criteria = this.formData.criteriaList[i];
        if (criteria.criteriaCode !== '' && criteria.criteriaCode !== null) {
          const code = criteria.criteriaCode;
          const endDateStr = CommonMixins.methods.common_getDateString(
            criteria.endDate,
          );

          const obj = { criteriaCode: code, endDate: endDateStr };
          criteriaList.push(obj);
        }
      }

      const srvcId = this.formData.srvcId;
      const pushRedirectUrl = this.formData.pushRedirectUrl;

      let formData = {
        srvcId,
        pushRedirectUrl,
        criteriaList,
      };

      this.insertAlarm(formData);
    },

    // 알람 등록
    async insertAlarm(formData) {
      this.$dialogs.loading();

      const { data } = await insertAlarm(formData);

      this.$dialogs.close();

      if (data) {
        this.$dialogs
          .alert(this.$i18n.t('common.alert-submit-success'))
          .then(res => {
            if (res) {
              this.initView();
            }
          });
      } else {
        this.$dialogs.alert(this.$i18n.t('common.alert-submit-fail'));
        this.initView();
      }
    },
  },
};
</script>
<style scoped>
@import url('./alarm.css');
</style>
